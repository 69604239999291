

.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (max-width: 767px) {
    .App-logo {
        animation: App-logo-spin infinite 20s linear;
    }
}
.home_icon {
    margin-right: auto;
}

.App-header {
    width: 100%;
    height: 70px;
    background-color: --primary-color;
    display: flex;
    align-items: center;
    padding: 0 1.5rem;
    justify-content: flex-end;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 1000;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}


/******* xister css **********/

body .brand-toyota {
  
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #f8f8f8;
  font-family: "Toyota Type", sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #282830;

  h1 {
    font-family: "Toyota Type", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #282830;
    font-size: 60px;
    line-height: 86px;
    margin: 0 0 26px 0;
  }
  h2 {
    font-family: "Toyota Type", sans-serif;
    font-style: normal;
    font-weight: 500;
    color: #282830;
    font-size: 22px;
    line-height: 31px;
    margin: 0 0 6px 0;
  }
  button {
    font-family: "Toyota Type", sans-serif;
    font-style: normal;
    font-weight: 500;
    background-color: #ff0022;
    color: #fff;
    font-size: 12px;
    line-height: 17px;
    border: none;
    height: 35px;
    min-width: 120px;
    white-space: nowrap;
    border-radius: 35px;
    padding: 0 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  button:hover {
    filter: opacity(0.85);
    transform: scale(1.05);
  }
  label {
    display: flex;
    align-items: center;
    width: fit-content;
  }
  input[type=radio] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #f8f8f8;
    /* Not removed via appearance */
    margin: 0 8px 0 4px;
    font: inherit;
    background-color: #f8f8f8;
    width: 22px;
    height: 22px;
    border: 1px solid #bdbdbd;
    border-radius: 50%;
    outline: none;
    display: grid;
    place-content: center;
  }
  input[type=radio]::before {
    content: "";
    width: 14px;
    height: 14px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 20px 20px #ff0022;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }
  input[type=radio]:checked::before {
    transform: scale(1);
  }
  input[type=radio]:focus, input[type=radio]:hover {
    border: 2px solid #ff0022;
  }
  .result-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 160px;
  }
  .result-page a {
    font-family: "Toyota Type", sans-serif;
    font-style: normal;
    font-weight: 500;
    background-color: #ff0022;
    color: #fff;
    font-size: 12px;
    line-height: 17px;
    border: none;
    height: 35px;
    min-width: 120px;
    white-space: nowrap;
    border-radius: 35px;
    padding: 0 20px;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    text-decoration: none;
    color: #fff;
  }
  .result-page a:hover {
    filter: opacity(0.85);
    transform: scale(1.05);
  }
}

.brand-toyota {
@font-face {
  font-family: "Nobel";
  src: url("./assets/fonts/Nobel-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Nobel";
  src: url("./assets/fonts/Nobel-RegularIt.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Nobel";
  src: url("./assets/fonts/Nobel-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Nobel";
  src: url("./assets/fonts/Nobel-BoldIt.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Nobel";
  src: url("./assets/fonts/Nobel-Black.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Nobel";
  src: url("./assets/fonts/Nobel-BlackIt.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
}
@font-face {
  font-family: "Nobel";
  src: url("./assets/fonts/Nobel-Book.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Nobel";
  src: url("./assets/fonts/Nobel-BookIt.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Nobel";
  src: url("./assets/fonts/Nobel-Light.woff2") format("woff2");
  font-weight: 100;
  font-style: normal;
}
@font-face {
  font-family: "Nobel";
  src: url("./assets/fonts/Nobel-LightIt.woff2") format("woff2");
  font-weight: 100;
  font-style: italic;
}
@font-face {
  font-family: "Toyota Type";
  src: url("./assets/fonts/ToyotaType-Regular.woff2") format("woff2");
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: "Toyota Type";
  src: url("./assets/fonts/ToyotaType-RegularIt.woff2") format("woff2");
  font-weight: 400;
  font-style: italic;
}
@font-face {
  font-family: "Toyota Type";
  src: url("./assets/fonts/ToyotaType-Semibold.woff2") format("woff2");
  font-weight: 500;
  font-style: normal;
}
@font-face {
  font-family: "Toyota Type";
  src: url("./assets/fonts/ToyotaType-SemiboldIt.woff2") format("woff2");
  font-weight: 500;
  font-style: italic;
}
@font-face {
  font-family: "Toyota Type";
  src: url("./assets/fonts/ToyotaType-Bold.woff2") format("woff2");
  font-weight: 600;
  font-style: normal;
}
@font-face {
  font-family: "Toyota Type";
  src: url("./assets/fonts/ToyotaType-BoldIt.woff2") format("woff2");
  font-weight: 600;
  font-style: italic;
}
@font-face {
  font-family: "Toyota Type";
  src: url("./assets/fonts/ToyotaType-Book.woff2") format("woff2");
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: "Toyota Type";
  src: url("./assets/fonts/ToyotaType-BookIt.woff2") format("woff2");
  font-weight: 300;
  font-style: italic;
}
@font-face {
  font-family: "Toyota Type";
  src: url("./assets/fonts/ToyotaType-Black.woff2") format("woff2");
  font-weight: 800;
  font-style: normal;
}
@font-face {
  font-family: "Toyota Type";
  src: url("./assets/fonts/ToyotaType-BlackIt.woff2") format("woff2");
  font-weight: 800;
  font-style: italic;
}

.footer {
  margin-top: auto;
  background: #e0e0e0;
  padding: 24px 120px 32px 120px;
}
@media only screen and (max-width: 768px) {
  .footer {
    padding: 16px;
    font-size: 12px;
  }
}

.sv_main {
  font-family: "Toyota Type", sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #282830;
}
.sv_main .sv_custom_header {
  background-color: transparent !important;
}
.header-t {
  width: 100%;
  height: 70px;
  background: #f8f8f8;
  border: 1px solid #bb261a;
  border-top: none;
  box-sizing: border-box;
  box-shadow: 4px 4px 20px 4px rgba(208, 208, 217, 0.52);
  border-radius: 0px 0px 40px 40px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  padding: 0 38px;
}
.header-t::before {
  content: "";
  height: 36px;
  width: 300px;
  background-image: url("data:image/svg+xml,%3Csvg width='53' height='36' viewBox='0 0 53 36' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M26.3817 0.400391C11.8116 0.400391 0 8.28011 0 18.0006C0 27.7211 11.8116 35.6008 26.3817 35.6008C40.9518 35.6008 52.7634 27.7211 52.7634 18.0006C52.7634 8.28011 40.9518 0.400391 26.3817 0.400391ZM26.3817 28.0452C24.2158 28.0452 22.4469 23.6444 22.3322 18.1049C23.6388 18.226 24.9935 18.2898 26.3817 18.2898C27.7699 18.2898 29.1246 18.226 30.4311 18.1049C30.3164 23.6448 28.5472 28.0452 26.3817 28.0452ZM22.6029 13.7093C23.1927 9.79396 24.6622 7.02017 26.3817 7.02017C28.1012 7.02017 29.5706 9.79396 30.1605 13.7093C28.952 13.8221 27.6863 13.8822 26.3817 13.8822C25.0771 13.8822 23.8114 13.8225 22.6029 13.7093ZM26.3817 2.94874C23.5489 2.94874 21.1396 7.3166 20.248 13.4121C14.8835 12.5481 11.1377 10.585 11.1377 8.30137C11.1377 5.21912 17.9628 2.7205 26.3817 2.7205C34.8006 2.7205 41.6256 5.21912 41.6256 8.30137C41.6256 10.5846 37.8799 12.5477 32.5153 13.4121C31.6237 7.317 29.2144 2.94874 26.3817 2.94874ZM3.7827 17.3403C3.7827 14.3576 4.89441 11.5621 6.83475 9.15697C6.81259 9.32584 6.80054 9.49552 6.80054 9.6668C6.80054 13.427 12.2654 16.6247 19.8883 17.804C19.8825 18.0768 19.8794 18.3516 19.8794 18.628C19.8794 25.632 21.784 31.5635 24.4122 33.5751C12.8537 32.8559 3.7827 25.8619 3.7827 17.3403ZM28.3508 33.5751C30.979 31.5635 32.8836 25.632 32.8836 18.628C32.8836 18.3516 32.8805 18.0768 32.8746 17.804C40.4976 16.6243 45.9624 13.4266 45.9624 9.6668C45.9624 9.49552 45.9508 9.32584 45.9282 9.15697C47.8686 11.5617 48.9803 14.3576 48.9803 17.3403C48.9807 25.8619 39.9096 32.8559 28.3508 33.5751Z' fill='%23282830'/%3E%3C/svg%3E%0A");
  background-repeat: no-repeat;
  background-size: contain;
}
.sv_main .sv_container {
  margin: 60px 64px;
}
@media only screen and (max-width: 768px) {
  .sv_main .sv_container {
    margin: 32px 16px;
  }
}
.sv_main .sv_container .sv_body .sv-string-viewer {
  word-break: keep-all;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_page_title {
  margin: 4px 0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_page_title span {
  font-family: "Toyota Type", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #282830;
  font-size: 22px;
  line-height: 31px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_page_title::after {
  content: "";
  width: 100%;
  height: 1px;
  background: #ff0022;
  display: block;
  margin-top: 31px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row {
  padding: 13px 35px;
  background: #f8f8f8;
  margin-bottom: 20px;
}
@media only screen and (max-width: 768px) {
  .sv_main .sv_container .sv_body .sv_p_root .sv_row {
    padding: 8px 16px;
  }
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row:nth-child(2n+1) {
  background: #e0e0e0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_erbox {
  font-family: "Toyota Type", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #282830;
  font-size: 12px;
  line-height: 12px;
  //margin-top: -12px;
  margin-bottom: 12px;
  color: #bb261a;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_title {
  font-family: "Toyota Type", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #282830;
  font-size: 16px;
  line-height: 23px;
  margin: 0 0 16px 0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_title .sv_q_num {
  //margin-right: -8px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_title .sv_q_required_text {
  //margin-left: -8px;
}
.sv_main .sv-boolean .sv-boolean__switch {
  background-color: #ed0322;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row input[type=text],
.sv_main .sv_container .sv_body .sv_p_root .sv_row textarea {
  font-family: "Toyota Type", sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #282830;
  border: 1px solid #bdbdbd;
  border-radius: 0;
  background: #f8f8f8;
  padding: 4px;
  font-size: 16px;
  line-height: 23px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row input[type=text]:hover,
.sv_main .sv_container .sv_body .sv_p_root .sv_row textarea:hover {
  border-color: #ff0022;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row input[type=text]:focus,
.sv_main .sv_container .sv_body .sv_p_root .sv_row textarea:focus {
  outline: none;
  border-color: #ff0022;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row textarea {
  width: 100%;
  box-sizing: border-box;
  width: -webkit-fill-available;
  min-height: 100px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix {
  width: 100%;
  margin: 0 0 30px 0;
  border-collapse: collapse;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix th,
.sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix td {
  border-bottom: 1px solid #bdbdbd;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix thead tr th {
  padding-bottom: 16px;
  width: 12.5%;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix thead tr th span {
  font-family: "Toyota Type", sans-serif;
  font-style: normal;
  font-weight: 500;
  color: #282830;
  font-size: 16px;
  line-height: 23px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix tbody tr td {
  font-size: 16px;
  line-height: 16px;
  padding: 14px 0;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix tbody tr td.sv_q_m_cell label.sv_q_m_label {
  position: relative;
  width: fit-content;
  margin: auto;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix tbody tr td.sv_q_m_cell label.sv_q_m_label span {
  display: none;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix tbody tr td span {
  margin-left: 16px;
  white-space: nowrap;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating {
  padding-left: 18px;
  margin-bottom: 16px;
}
@media only screen and (max-width: 768px) {
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating {
    padding-left: 0;
  }
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  align-items: center;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_min_text,
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_max_text {
  margin: 0 8px;
  text-align: center;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_item {
  outline: 1px solid #bdbdbd;
  margin: 0.5px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_item input {
  display: none;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_item .sv_q_rating_item_text {
  font-size: 16px;
  line-height: 16px;
  height: 27px;
  width: 27px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_item:hover {
  z-index: 1;
  outline: 3px solid #ff0022;
  outline-offset: -2px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_item.active .sv_q_rating_item_text {
  background-color: #ff0022;
  border: none;
  color: #fff;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qcbc .sv_q_radiogroup {
  margin-bottom: 8px;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qcbc .sv_q_radiogroup .sv_q_radiogroup_label {
  cursor: pointer;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper {
  border: 1px solid #bdbdbd;
  border-radius: 0;
  background: #f8f8f8;
  width: fit-content;
  position: relative;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper::after {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 4px;
  width: 15px;
  height: 8px;
  clip-path: polygon(100% 0%, 0 0%, 50% 100%);
  background: #bdbdbd;
  pointer-events: none;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper:hover {
  border-color: #ff0022;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper:focus {
  outline: none;
  border-color: #ff0022;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper select {
  padding: 4px 20px 4px 4px;
  -webkit-appearance: none;
  appearance: none;
  background-color: transparent;
  margin: 0;
  border: none;
  cursor: pointer;
  z-index: 1;
  outline: none;
  font-family: "Toyota Type", sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #282830;
}
.sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper select::-ms-expand {
  display: none;
}
.sv_main .sv_container .sv_body {
  border-top: none;
}
.sv_main .sv_container .sv_body .sv_nav {
  margin-top: 24px;
  display: flex;
  flex-direction: row;
  align-items: center;
}
.sv_main .sv_container .sv_body .sv_nav input[type=button] {
  font-family: "Toyota Type", sans-serif;
  font-style: normal;
  font-weight: 500;
  background-color: #ff0022;
  color: #fff;
  font-size: 12px;
  line-height: 17px;
  border: none;
  height: 35px;
  min-width: 120px;
  white-space: nowrap;
  border-radius: 35px;
  padding: 0 20px;
  cursor: pointer;
  transition: all 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
}
.sv_main .sv_container .sv_body .sv_nav input[type=button]:hover {
  filter: opacity(0.85);
  transform: scale(1.05);
}
.sv_main .sv_container .sv_body .sv_nav .sv_next_btn,
.sv_main .sv_container .sv_body .sv_nav .sv_complete_btn {
  margin-left: auto;
}

.progress {
  height: 2px;
  background-color: #E5E5E5;
  position: relative;
  margin-bottom: 30px;
  
  .bar {
    position: relative;
    height: 100%;
    background-color: #ff0022;
    overflow: hidden;
  }
  .text {
    position: absolute;
    margin-top: calc(1 * var(--base-unit, 8px));
    right: calc(3 * var(--base-unit, 8px));
    color: #282830;
    font-size: 12px;
    line-height: 16px;
    font-weight: 600;
  }
}

/*# sourceMappingURL=toyota.css.map */
}

/**********************************************************/

body .brand-lexus {
  
  margin: 0;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  background-color: #fafafa;
  font-family: "Nobel", sans-serif;
  font-style: normal;
  font-weight: 300;
  color: #000;
  
  h1 {
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #000;
    font-size: 60px;
    line-height: 69px;
    margin: 0 0 26px 0;
  }
  h2 {
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #000;
    font-size: 22px;
    line-height: 25px;
    margin: 0 0 6px 0;
  }
  button {
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 500;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    border: none;
    height: 35px;
    min-width: 120px;
    white-space: nowrap;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: left;
    text-transform: uppercase;
    padding: 0 50px 0 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='8' viewBox='0 0 29 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 1L28 4M28 4L25 7M28 4H0' stroke='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 15px);
    background-position-y: 50%;
  }
  button:hover {
    filter: opacity(0.8);
    transform: scale(1.05);
  }
  label {
    display: flex;
    align-items: center;
    width: fit-content;
  }
  input[type=radio] {
    /* Add if not using autoprefixer */
    -webkit-appearance: none;
    /* Remove most all native input styles */
    appearance: none;
    /* For iOS < 15 */
    background-color: #fafafa;
    /* Not removed via appearance */
    margin: 0 8px 0 4px;
    font: inherit;
    background-color: #fafafa;
    width: 22px;
    height: 22px;
    border: 1px solid #999999;
    border-radius: 50%;
    outline: none;
    display: grid;
    place-content: center;
  }
  input[type=radio]::before {
    content: "";
    width: 14px;
    height: 14px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    transform: scale(0);
    transition: 120ms transform ease-in-out;
    box-shadow: inset 20px 20px #000;
    /* Windows High Contrast Mode */
    background-color: CanvasText;
  }
  input[type=radio]:checked::before {
    transform: scale(1);
  }
  input[type=radio]:focus, input[type=radio]:hover {
    border: 2px solid #000;
  }
  .result-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: 160px;
  }
  .result-page a {
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 500;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    border: none;
    height: 35px;
    min-width: 120px;
    white-space: nowrap;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: left;
    text-transform: uppercase;
    padding: 0 50px 0 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='8' viewBox='0 0 29 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 1L28 4M28 4L25 7M28 4H0' stroke='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 15px);
    background-position-y: 50%;
    text-decoration: none;
  }
  .result-page a:hover {
    filter: opacity(0.8);
    transform: scale(1.05);
  }
}

.brand-lexus {
  @font-face {
    font-family: "Nobel";
    src: url("./assets/fonts/Nobel-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "Nobel";
    src: url("./assets/fonts/Nobel-RegularIt.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: "Nobel";
    src: url("./assets/fonts/Nobel-Bold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Nobel";
    src: url("./assets/fonts/Nobel-BoldIt.woff2") format("woff2");
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: "Nobel";
    src: url("./assets/fonts/Nobel-Black.woff2") format("woff2");
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: "Nobel";
    src: url("./assets/fonts/Nobel-BlackIt.woff2") format("woff2");
    font-weight: 800;
    font-style: italic;
  }
  @font-face {
    font-family: "Nobel";
    src: url("./assets/fonts/Nobel-Book.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Nobel";
    src: url("./assets/fonts/Nobel-BookIt.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: "Nobel";
    src: url("./assets/fonts/Nobel-Light.woff2") format("woff2");
    font-weight: 100;
    font-style: normal;
  }
  @font-face {
    font-family: "Nobel";
    src: url("./assets/fonts/Nobel-LightIt.woff2") format("woff2");
    font-weight: 100;
    font-style: italic;
  }
  @font-face {
    font-family: "Toyota Type";
    src: url("./assets/fonts/ToyotaType-Regular.woff2") format("woff2");
    font-weight: 400;
    font-style: normal;
  }
  @font-face {
    font-family: "Toyota Type";
    src: url("./assets/fonts/ToyotaType-RegularIt.woff2") format("woff2");
    font-weight: 400;
    font-style: italic;
  }
  @font-face {
    font-family: "Toyota Type";
    src: url("./assets/fonts/ToyotaType-Semibold.woff2") format("woff2");
    font-weight: 500;
    font-style: normal;
  }
  @font-face {
    font-family: "Toyota Type";
    src: url("./assets/fonts/ToyotaType-SemiboldIt.woff2") format("woff2");
    font-weight: 500;
    font-style: italic;
  }
  @font-face {
    font-family: "Toyota Type";
    src: url("./assets/fonts/ToyotaType-Bold.woff2") format("woff2");
    font-weight: 600;
    font-style: normal;
  }
  @font-face {
    font-family: "Toyota Type";
    src: url("./assets/fonts/ToyotaType-BoldIt.woff2") format("woff2");
    font-weight: 600;
    font-style: italic;
  }
  @font-face {
    font-family: "Toyota Type";
    src: url("./assets/fonts/ToyotaType-Book.woff2") format("woff2");
    font-weight: 300;
    font-style: normal;
  }
  @font-face {
    font-family: "Toyota Type";
    src: url("./assets/fonts/ToyotaType-BookIt.woff2") format("woff2");
    font-weight: 300;
    font-style: italic;
  }
  @font-face {
    font-family: "Toyota Type";
    src: url("./assets/fonts/ToyotaType-Black.woff2") format("woff2");
    font-weight: 800;
    font-style: normal;
  }
  @font-face {
    font-family: "Toyota Type";
    src: url("./assets/fonts/ToyotaType-BlackIt.woff2") format("woff2");
    font-weight: 800;
    font-style: italic;
  }
  
  .footer {
    margin-top: auto;
    background: #b9c0c2;
    padding: 24px 120px 32px 120px;
  }
  @media only screen and (max-width: 768px) {
    .footer {
      padding: 16px;
      font-size: 12px;
    }
  }
  
  .sv_main {
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #000;
  }
  
  .sv_main .sv_custom_header {
    background-color: transparent !important;
  }
  .header-l {
    width: 100%;
    height: 70px;
    background: #000;
    border-top: none;
    box-sizing: border-box;
    box-shadow: 4px 4px 20px 4px rgba(208, 208, 217, 0.52);
    border-radius: 0px 0px 40px 40px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
    padding: 38px 0;
  }
  .header-l::before {
    content: "";
    height: 68px;
    width: 300px;
    background-image: url("./assets/images/logo_lexus_on_black\ 1.png");
    background-repeat: no-repeat;
    background-size: contain;
    background-position-y: -4px;
  }
  .sv_main .sv_container {
    margin: 60px 64px;
  }
  @media only screen and (max-width: 768px) {
    .sv_main .sv_container {
      margin: 32px 16px;
    }
  }

  .sv_main .sv_container .sv_body {
    border-top: none;
  }

  .sv_main .sv_container .sv_body .sv-string-viewer {
    word-break: keep-all;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_page_title {
    margin: 4px 0;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_page_title span {
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #000;
    text-transform: uppercase;
    font-size: 22px;
    line-height: 25px;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_page_title::after {
    content: "";
    width: 100%;
    height: 1px;
    background: #000;
    display: block;
    margin-top: 31px;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row {
    padding: 13px 35px;
    background: #fafafa;
    margin-bottom: 20px;
  }
  @media only screen and (max-width: 768px) {
    .sv_main .sv_container .sv_body .sv_p_root .sv_row {
      padding: 8px 16px;
    }
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row:nth-child(2n+1) {
    background: #b9c0c2;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qstn .sv_q_erbox {
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #000;
    font-size: 12px;
    line-height: 12px;
    //margin-top: -12px;
    margin-bottom: 12px;
    color: #bb261a;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_title {
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #000;
    font-size: 16px;
    line-height: 18px;
    margin: 0 0 16px 0;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_title .sv_q_num {
    //margin-right: -6px;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_title .sv_q_required_text {
    //margin-left: -6px;
  }
  .sv_main .sv-boolean .sv-boolean__switch {
    background-color: #000000;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row input[type=text],
  .sv_main .sv_container .sv_body .sv_p_root .sv_row textarea {
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #000;
    border: 1px solid #999999;
    border-radius: 0;
    background: #fafafa;
    padding: 4px;
    font-size: 16px;
    line-height: 18px;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row input[type=text]:hover,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row textarea:hover {
    border-color: #000;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row input[type=text]:focus,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row textarea:focus {
    outline: none;
    border-color: #000;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row textarea {
    width: 100%;
    box-sizing: border-box;
    width: -webkit-fill-available;
    min-height: 100px;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix {
    width: 100%;
    margin: 0 0 30px 0;
    border-collapse: collapse;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix th,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix td {
    border-bottom: 1px solid #999999;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix thead tr th {
    padding-bottom: 16px;
    width: 12.5%;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix thead tr th span {
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 600;
    color: #000;
    font-size: 16px;
    line-height: 18px;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix tbody tr td {
    font-size: 16px;
    line-height: 16px;
    padding: 14px 0;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix tbody tr td.sv_q_m_cell label.sv_q_m_label {
    position: relative;
    width: fit-content;
    margin: auto;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix tbody tr td.sv_q_m_cell label.sv_q_m_label span {
    display: none;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row table.sv_q_matrix tbody tr td span {
    margin-left: 16px;
    white-space: nowrap;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating {
    padding-left: 18px;
    margin-bottom: 16px;
  }
  @media only screen and (max-width: 768px) {
    .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating {
      padding-left: 0;
    }
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset {
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    align-items: center;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_min_text,
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_max_text {
    margin: 0 8px;
    text-align: center;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_item {
    outline: 1px solid #999999;
    margin: 0.5px;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_item input {
    display: none;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_item .sv_q_rating_item_text {
    font-size: 16px;
    line-height: 16px;
    height: 27px;
    width: 27px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_item:hover {
    z-index: 1;
    outline: 3px solid #000;
    outline-offset: -2px;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_q_rating fieldset .sv_q_rating_item.active .sv_q_rating_item_text {
    background-color: #000;
    border: none;
    color: #fff;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qcbc .sv_q_radiogroup {
    margin-bottom: 8px;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qcbc .sv_q_radiogroup .sv_q_radiogroup_label {
    cursor: pointer;
    position: relative;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_qcbc .sv_q_radiogroup.checked .sv_q_radiogroup_label::before {
    background: #000;
    outline-color: #fafafa;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper {
    border: 1px solid #999999;
    border-radius: 0;
    background: #fafafa;
    width: fit-content;
    position: relative;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper::after {
    content: "";
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 4px;
    width: 15px;
    height: 8px;
    clip-path: polygon(100% 0%, 0 0%, 50% 100%);
    background: #999999;
    pointer-events: none;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper:hover {
    border-color: #000;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper:focus {
    outline: none;
    border-color: #000;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper select {
    padding: 4px 20px 4px 4px;
    -webkit-appearance: none;
    appearance: none;
    background-color: transparent;
    margin: 0;
    border: none;
    cursor: pointer;
    z-index: 1;
    outline: none;
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 300;
    color: #000;
  }
  .sv_main .sv_container .sv_body .sv_p_root .sv_row .sv_select_wrapper select::-ms-expand {
    display: none;
  }
  .sv_main .sv_container .sv_body .sv_nav {
    margin-top: 24px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }
  .sv_main .sv_container .sv_body .sv_nav input[type=button] {
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 500;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    border: none;
    height: 35px;
    min-width: 120px;
    white-space: nowrap;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: left;
    text-transform: uppercase;
    padding: 0 50px 0 15px;
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='8' viewBox='0 0 29 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 1L28 4M28 4L25 7M28 4H0' stroke='white'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position-x: calc(100% - 15px);
    background-position-y: 50%;
  }
  .sv_main .sv_container .sv_body .sv_nav input[type=button]:hover {
    filter: opacity(0.8);
    transform: scale(1.05);
  }
  .sv_main .sv_container .sv_body .sv_nav input[type=button].sv_prev_btn {
    font-family: "Nobel", sans-serif;
    font-style: normal;
    font-weight: 500;
    background-color: #000;
    color: #fff;
    font-size: 12px;
    line-height: 14px;
    border: none;
    height: 35px;
    min-width: 120px;
    white-space: nowrap;
    border-radius: 0;
    cursor: pointer;
    transition: all 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: left;
    text-transform: uppercase;
    padding: 0 15px 0 50px;
    background-image: url("data:image/svg+xml,%3Csvg width='28' height='8' viewBox='0 0 29 8' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M25 1L28 4M28 4L25 7M28 4H0' stroke='white' transform='rotate(180, 14, 4)'/%3E%3C/svg%3E%0A");
    background-repeat: no-repeat;
    background-position-x: 15px;
    background-position-y: 50%;
  }
  .sv_main .sv_container .sv_body .sv_nav input[type=button].sv_prev_btn:hover {
    filter: opacity(0.8);
    transform: scale(1.05);
  }
  .sv_main .sv_container .sv_body .sv_nav input[type=button].sv_next_btn, .sv_main .sv_container .sv_body .sv_nav input[type=button].sv_complete_btn {
    margin-left: auto;
  }
  
  .progress {
    height: 2px;
    background-color: #E5E5E5;
    position: relative;
    margin-bottom: 30px;
    
    .bar {
      position: relative;
      height: 100%;
      background-color: #000;
      overflow: hidden;
    }
    .text {
      position: absolute;
      margin-top: calc(1 * var(--base-unit, 8px));
      right: calc(3 * var(--base-unit, 8px));
      color: #000;
      font-size: 12px;
      line-height: 16px;
      font-weight: 600;
    }
  }
  /*# sourceMappingURL=lexus.css.map */
  
}
/*************************************/
